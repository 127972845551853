export default {
  dynamicTips1: 'Fixed header, sorted by header order',
  dynamicTips2: 'Not fixed header, sorted by click order',
  dragTips1: 'The default order',
  dragTips2: 'The after dragging order',
  title: 'Title',
  importance: 'Imp',
  type: 'Type',
  remark: 'Remark',
  search: 'Search',
  add: 'Add',
  data_source_search: 'Search Data Source...',
  export: 'Export',
  reviewer: 'reviewer',
  id: 'ID',
  date: 'Date',
  start_date_interval: 'Start Date Interval',
  earliest_date: 'Earliest Date',
  latest_date: 'Latest Date',
  noDateAvailable: 'No date available',
  author: 'Author',
  readings: 'Readings',
  status: 'Status',
  actions: 'Actions',
  edit: 'Edit',
  editSettings: 'Edit settings',
  total_rows: 'Total rows',
  flow_ids_to_run: 'Flows to run (optional)',
  flow_ids_to_run_tooltip: 'Select Flows which you want to run after file upload',
  flow_ids_restricted: 'Flows with access to the upload (optional)',
  flow_ids_restricted_tooltip: 'A list of Flows that can access the file upload. All other Flows that are not listed won\'t be able to access the file upload.',
  run_flow: 'Run Flow',
  view: 'View',
  copy: 'Copy',
  sharing: 'Sharing',
  versioning: 'Versioning history',
  reimport_data: 'Reimport data',
  confirm: 'Confirm',
  publish: 'Publish',
  draft: 'Draft',
  delete: 'Delete',
  name: 'Name',
  first_name: 'First Name',
  last_name: 'Last Name',
  description: 'Description',
  email: 'Email',
  phone: 'Mobile',
  company_name: 'Company name',
  flow_name: 'Flow Name',
  flow_data_retention: 'Flow Data Retention Days',
  user_type: 'User Type',
  reference: 'Env Reference Shortcut',
  created_date: 'Created',
  created_by: 'Created by',
  updated_date: 'Last Updated',
  updated_by: 'Updated by',
  successful_runs: 'Successful Runs (Last week)',
  failed_runs: 'Failed Runs (Last week)',
  webhook_notice: 'To use a webhook set it first in the flow as a trigger',
  webhook_token_notice: 'The token should start with `Bearer ey...` and adds security.',
  notify_email: 'Email (list) - Notification in case of flow run errors',
  notify_email_tooltip: 'Enter a comma separated list of emails that are notified in case of any flow run errors when the flow is on status Active.',
  automatic_retries_name: 'Automatically retry steps with errors (for status active only)',
  connector_search: 'Search Connector...',
  connector_action_search: 'Search Actions...',
  connector_auth_search: 'Search Connector Auth...',
  connector_auth: {
    connection_method: 'Connection method',
    ssh_tunnel: {
      ssh_host: 'SSH Host',
      ssh_port: 'SSH Port',
      ssh_user: 'SSH User',
      ssh_public_key: 'Public key',
    },
  },
  tooltip_apiendpoint: 'Base domain if you use a custom endpoint, e.g. for an old API version or including your own subdomain',
  company_search: 'Search Companies...',
  flow_search: 'Search Flows...',
  user_search: 'Search user...',
  team_search: 'Search by team...',
  uploaded_file_search: 'Search files  ...',
  insight_search: 'Search Insights ...',
  transformation_search: 'Search Transformations ...',
  activity_search: 'Filter for endpoint...',
  choose_file: 'Choose file',
  drop_file: 'Drop file here or ',
  click_to_upload: 'click to upload',
  upload_allowed: 'Allowed: CSV, JSON, TXT, XLS, XLSX or XML-files with a size less than 10 MB',
  cancel_upload: 'Cancel upload',
  team_members: 'Team members',
  noData: 'No history data yet. Start editing and saving to see history snapshots (versions).',
  file_validation_error_message: {
    text: [
      'Not all rows passed the file validation, click ',
      ' to see the rows with errors',
    ],
    link: 'here',
  },
  file_upload_success_notification: {
    create: {
      title: 'Created!',
      message: 'File Upload has been created!',
    },
    update: {
      title: 'File Upload update!',
      message: 'File Upload has been updated successfully!',
    },
  },
  reimport_disabled_button_text: 'Reimport data is not available for custom data sources. Please delete the data source instead and run the flow again.',
  resend_temp_password: 'Resend temporary password',
  file_not_uploaded: 'Choose the file that you want to upload',
  cannot_remove_file: 'A successfully uploaded file cannot be removed',
  insufficient_permissions: 'Insufficient permissions to { tooltipAction }',
  view_sharing_settings: 'View sharing settings',
  view_versioning_history: 'View versioning history',
}
