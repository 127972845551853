<template>
  <el-form-item class="lo-expand-list-form-item">
    <template v-if="element.info || element.title" #label>
      <label>
        {{ elementTitle }}
        <el-tooltip
          v-if="element.info"
          placement="bottom-end"
          :content="elementInfo"
        >
          <el-icon><InfoFilled /></el-icon>
        </el-tooltip>
      </label>
    </template>

    <el-space direction="vertical" :size="5" :fill="true" class="w-100">
      <el-divider />
      <el-row
        v-for="(item, index) in model[element.key]"
        :key="index"
        class="expand-list-item-wrapper"
      >
        <el-space class="w-100">
          <el-row class="pl-10">
            <form-item
              v-for="(innerValue, innerKey) in item"
              :key="innerKey"
              :expand-list-index="index"
              :element-key="innerKey"
              :rule-model="model[element.key][index]"
              :validation-props="validationProps[element.key][index]"
              :element="element.nested_schema[innerKey]"
              :original-rule-model="originalRuleModel"
              :selected-connector-auth="selectedConnectorAuth"
              :current-connector="currentConnector"
              @rule-model-change="onRuleChange"
              @field-focused="$emit('fieldFocused', $event)"
            />
          </el-row>
          <el-button
            plain
            type="danger"
            @click="onDeleteEntry(index)"
          >
            <template #icon>
              <el-icon><Delete /></el-icon>
            </template>
          </el-button>
        </el-space>
        <el-divider />
      </el-row>

      <div class="pl-10">
        <el-button
          type="primary"
          plain
          @click="onCreateEntry"
        >
          <template #icon>
            <el-icon><Plus /></el-icon>
          </template>
        </el-button>
      </div>
    </el-space>
  </el-form-item>
</template>

<script>
import { Plus, Delete, InfoFilled } from '@element-plus/icons-vue'
import * as R from 'ramda'
import { useSchemaFormModelHelper } from '../../utils/SchemaFormModelHelper'
import FormItem from '@/ui-libs/schema-form-renderer/components/formItems/FormItem.vue'
import { translateSchemaItem } from '@/ui-libs/schema-form-renderer/lang/index.js'

/* eslint-disable vue/require-prop-types */
export default {
  name: 'ExpandListFormItem',
  components: {
    Delete,
    FormItem,
    InfoFilled,
    Plus,
  },
  inject: ['i18n'],
  props: [
    'element',
    'ruleModel',
    'elementKey',
    'validationProps',
    'originalRuleModel',
    'selectedConnectorAuth',
    'currentConnector',
  ],
  emits: [
    'ruleModelChange',
    'fieldFocused',
  ],
  setup() {
    const schemaFormModelHelper = useSchemaFormModelHelper()
    return { schemaFormModelHelper }
  },
  data() {
    return {
      isEmbed: process.env.APPLICATION_TYPE === 'Embed',
    }
  },
  computed: {
    elementTitle() {
      return translateSchemaItem(this.$i18n.locale, this.element.title)
    },
    elementInfo() {
      return translateSchemaItem(this.$i18n.locale, this.element.info)
    },
    model: {
      get() {
        return this.ruleModel
      },
      set(newData) {
        const data = newData.value || newData
        this.$emit('ruleModelChange', { value: data, elementKey: this.elementKey })
      },
    },
  },
  methods: {
    onRuleChange({ value, elementKey, expandListIndex }) {
      const updatedModel = R.clone(this.model)
      updatedModel[this.elementKey][expandListIndex][elementKey] = value[elementKey]
      this.model = updatedModel
    },
    onCreateEntry() {
      const newModel = this.schemaFormModelHelper.createFormModel(this.element.nested_schema, null, null, true)
      this.model[this.element.key].push(newModel)
    },
    onDeleteEntry(index) {
      const cloneOfModel = R.clone(this.model)
      cloneOfModel[this.element.key].splice(index, 1)
      this.model = cloneOfModel
    },
  },

}
</script>

<style lang="scss" scoped>
.lo-expand-list-form-item {
  :deep(.el-divider) {
    margin: 10px 0; // override default spacing for divider from 25px (too much)
  }
}

.expand-list-item-wrapper {
  :deep(.el-space__item:first-child) {
    width: 100%; // so that the content of the item fills space up to the delete button
  }
}
</style>
