export const errorResetTime = 5000
/**
 * The delay in milliseconds before showing the el-tooltip
 */
export const SHOW_TOOLTIP_AFTER_DELAY_MS = 700

/** @enum {string} */
export const DialogStatusEnum = {
  close: 'close',
  create: 'create',
  update: 'update',
}

export const DialogName = {
  COMPANY: 'company',
  CONNECTOR: 'connector',
  CONNECTOR_ACTION: 'connector-action',
  CONNECTOR_AUTH: 'connector-auth',
  DATA_SOURCE: 'data-source',
  EMBED_CONFIG: 'embed-config',
  EMBED_FLOW: 'embed-flow',
  EMBED_GROUP: 'embed-group',
  EMBED_PARTNER: 'embed-partner',
  EMBED_USE_CASE: 'embed-use-case',
  ENV_VARIABLE: 'env-variable',
  FILE_UPLOAD: 'file-upload',
  FLOW: 'flow',
  PERSONAL_ACCESS_TOKEN: 'personal-access-token',
  TEAM: 'team',
  TRANSFORMATION: 'transformation',
  USER: 'user',
}

export const HttpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
}
