export default {
  dynamicTips1: 'Fixer Header, sortiert nach Header-Reihenfolge',
  dynamicTips2: 'Nicht fester Header, sortiert nach Klickreihenfolge',
  dragTips1: 'Orden por defecto',
  dragTips2: 'The after dragging order',
  importance: 'Importancia',
  type: 'Typ',
  remark: 'Remark',
  search: 'Suchen',
  add: 'Hinzufügen',
  export: 'Exportieren',
  reviewer: 'Reviewer',
  id: 'ID',
  date: 'Datum',
  start_date_interval: 'Startdatum Intervall',
  earliest_date: 'Frühestes Datum',
  latest_date: 'Spätestes Datum',
  noDateAvailable: 'Kein Datum verfügbar',
  author: 'Author',
  readings: 'Lektor',
  status: 'Status',
  actions: 'Aktionen',
  edit: 'Editieren',
  editSettings: 'Einstellungen bearbeiten',
  total_rows: 'Gesamtzeilen',
  flow_ids_to_run: 'Flows für Automatisierungslauf (optional)',
  flow_ids_to_run_tooltip: 'Flows, die nach dem Upload gestartet werden sollen.',
  flow_ids_restricted: 'Flows mit Zugriff auf die Datei (optional)',
  flow_ids_restricted_tooltip: 'Liste von Flows, die auf die Datei zugreifen können. Nicht aufgeführte Flows können nicht auf die Datei zugreifen.',
  run_flow: 'Flow starten',
  view: 'Ansehen',
  copy: 'Kopieren',
  sharing: 'Zugriffsrechte',
  versioning: 'Versions-Historie',
  reimport_data: 'Daten Reimport',
  confirm: 'Bestätigen',
  publish: 'Publizieren',
  draft: 'Draft',
  delete: 'Löschen',
  name: 'Name',
  first_name: 'Vorname',
  last_name: 'Nachname',
  description: 'Beschreibung',
  email: 'E-mail',
  phone: 'Mobilnummer',
  company_name: 'Firmenname',
  flow_name: 'Flow Name',
  flow_data_retention: 'Tage Datenspeicherung von Flows ',
  reference: 'Env Reference Abkürzung',
  created_date: 'Erstellungsdatum',
  created_by: 'Erstellt von',
  updated_date: 'Zuletzt aktualisiert',
  updated_by: 'Aktualisiert von',
  successful_runs: 'Erfolgreiche Läufe (Letzte Woche)',
  failed_runs: 'Fehlgeschlagene Läufe (Letzte Woche)',
  user_type: 'Benutzer Typ',
  webhook_notice: 'Um einen Webhook zu nutzen, setze ihn im Flow',
  webhook_token_notice: 'Der Token sollte mit `Bearer ey...` beginnen.',
  notify_email: 'E-Mail-Liste - Zur Benachrichtigung bei Flow-Run-Fehlern',
  notify_email_tooltip: 'Durch Kommas getrennte Liste von E-Mails, die bei Flow-Ausführungsfehlern benachrichtigt werden, wenn der Flow im Status Active ist.',
  automatic_retries_name: 'Schritte mit Fehlern automatisch wiederholen (nur bei Status aktiv) ',
  connector_search: 'Connector suchen...',
  connector_action_search: 'Con Action suchen...',
  connector_auth_search: 'Connector Auth suchen...',
  connector_auth: {
    connection_method: 'Verbindungsmethode',
    ssh_tunnel: {
      ssh_host: 'SSH Host',
      ssh_port: 'SSH Port',
      ssh_user: 'SSH User',
      ssh_public_key: 'Public key',
    },
  },
  data_source_search: 'Data Source suchen...',
  tooltip_apiendpoint: 'Base Domain wenn Sie einen benutzerdefinierten Endpunkt verwenden, z.B. für eine alte API-Version oder unter Einbeziehung Ihrer eigenen Subdomain',
  company_search: 'Firmen suchen...',
  flow_search: 'Flows suchen...',
  user_search: 'Benutzer suchen...',
  team_search: 'Nach Team suchen...',
  uploaded_file_search: 'Suche Dateien ...',
  insight_search: 'Suche Insights ...',
  transformation_search: 'Suche Transformations ...',
  activity_search: 'Endpoint filtern...',
  choose_file: 'Datei auswählen',
  drop_file: 'Datei hier ablegen oder ',
  click_to_upload: 'klicke hier zum hochladen',
  upload_allowed: 'Erlaubt: CSV-, JSON-, TXT-, XLS-, XLSX- oder XML-Dateien mit weniger als 10 MB',
  cancel_upload: 'Upload abbrechen',
  team_members: 'Team Mitglieder',
  noData: 'Noch keine Versionsdaten. Beginnen Sie mit der Bearbeitung und dem Speichern, um Snapshots (Versionen) anzuzeigen.',
  file_validation_error_message: {
    text: [
      'Nicht alle Zeilen haben die Datei-Validierung erfolgreich durchlaufen. Klicken Sie ',
      ', um die fehlerhaften Zeilen zu sehen',
    ],
    link: 'hier',
  },
  file_upload_success_notification: {
    create: {
      title: 'Erstellt!',
      message: 'Datei-Upload wurde erstellt!',
    },
    update: {
      title: 'Datei-Upload-Update!',
      message: 'File Upload wurde erfolgreich aktualisiert!',
    },
  },
  reimport_disabled_button_text: 'Der Reimport von Daten ist für benutzerdefinierte Data Sources nicht verfügbar. Bitte lösch stattdessen die Data Sources und führ den Flow erneut aus.',
  resend_temp_password: 'Temporäres Passwort erneut senden',
  file_not_uploaded: 'Wählen Sie die Datei aus, die Sie hochladen möchten',
  cannot_remove_file: 'Eine erfolgreich hochgeladene Datei kann nicht entfernt werden',
  insufficient_permissions: 'Unzureichende Berechtigungen zum { tooltipAction }',
  view_sharing_settings: 'Ansichts-Freigabeeinstellungen',
  view_versioning_history: 'Versionsverlauf anzeigen',
}
