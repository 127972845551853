export const RouteName = {
  // Base
  LOGIN: 'Login',
  INDEX: 'Index',
  FLOW_MONITORING: 'FlowMonitoring',
  FLOWS_INDEX: 'FlowsIndex',
  FLOWS: 'Flows',
  FLOW_BUILDER: 'FlowBuilder',
  FLOW_DEBUGGER: 'FlowDebugger',
  COMMUNITY_LIBRARY: 'CommunityLibrary',
  PROFILE: 'Profile',
  COMPANY_PROFILE: 'CompanyProfile',
  CONNECTOR_AUTH: 'ConnectorAuth',
  FILE_UPLOAD: 'FileUpload',
  ENV_VARIABLES: 'EnvVariables',
  ACTIVITY_LOG: 'ActivityLog',
  NOT_FOUND: '404',

  // Dashboards
  DASHBOARD_TRANSFORMATION: 'DashboardTransformation',
  DASHBOARD_DATA_SOURCE: 'DashboardDataSource',

  // Embedding
  EMBEDDING_INDEX: 'EmbeddingIndex',
  EMBEDDING: 'Embedding',
  EMBEDDING_INTEGRATIONS: 'EmbeddingIntegrations',
  EMBEDDING_CONNECTOR_AUTHS: 'EmbeddingConnectorAuths',
  EMBEDDING_ACTIVITY_LOG: 'EmbeddingActivityLog',
  EMBED_GROUPS_INDEX: 'EmbedGroupsIndex',
  EMBED_GROUPS: 'EmbedGroups',
  EMBED_GROUP_FLOWS: 'EmbedGroupFlows',
  EMBED_PARTNERS_INDEX: 'EmbedPartnersIndex',
  EMBED_PARTNERS: 'EmbedPartners',
  EMBED_USE_CASES_INDEX: 'EmbedUseCasesIndex',
  EMBED_USE_CASES: 'EmbedUseCases',
  EMBED_USE_CASE_FLOWS: 'EmbedUseCaseFlows',

  // Settings
  SETTINGS: 'Settings',
  CONNECTOR_INDEX: 'ConnectorIndex',
  CONNECTOR: 'Connector',
  CONNECTOR_ACTIONS: 'ConnectorActions',
  USERS: 'Users',
  TEAMS: 'Teams',
  COMPANY: 'Company',
  ROLES: 'Roles',
}
