<template>
  <div
    v-if="!isVisible"
    @click="isVisible = true"
  >
    <icon icon="language" />
  </div>
  <el-dropdown
    v-else
    ref="dropdown"
    trigger="click"
    @command="handleSetLanguage"
    @visible-change="isVisible = $event"
  >
    <div>
      <icon icon="language" />
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item :disabled="language === LANGUAGES.de" :command="LANGUAGES.de" data-test="german">
          Deutsch
        </el-dropdown-item>
        <el-dropdown-item :disabled="language === LANGUAGES.en" :command="LANGUAGES.en" data-test="english">
          English
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { userApi } from '@/ui/api/user'
import { LANGUAGES } from '@/ui/consts'

export default {
  name: 'LangSelect',
  data() {
    return {
      isVisible: false,
      LANGUAGES,
    }
  },
  computed: {
    ...mapState({
      language: (state) => state.app.language,
    }),
    ...mapGetters({
      customUserData: 'user/customData',
    }),
  },
  watch: {
    isVisible() {
      this.$nextTick(() => {
        if (!this.isVisible) return
        this.$refs.dropdown.handleOpen()
      })
    },
  },
  methods: {
    ...mapActions({
      setUserCustomData: 'user/setCustomData',
    }),
    async handleSetLanguage(lang) {
      await this.$store.dispatch('app/setLanguage', lang)
      this.$message({
        message: 'Switch Language Success',
        type: 'success',
      })

      const { data } = await userApi.update({ id: this.customUserData.id, preferred_language: lang })

      this.setUserCustomData(data)
    },
  },
}
</script>
