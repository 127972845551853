import { RouteName } from '@/ui/router/consts.js'
import store from '@/ui/store'
import { connectorApi } from '@/ui/api/connector.js'
import {
  embeddedApi as embeddedApiV1,
  embedGroupsApi as embedGroupsApiV1,
} from '@/ui/api/embedded.js'
import {
  embeddedApi as embeddedApiV2,
  embedPartnerApi as embedPartnerApiV2,
  embedUseCaseApi as embedUseCaseApiV2,
} from '@/ui/api/v2/embedded.js'
import { flowApi } from '@/ui/api/flow.js'

/**
 * name: 'routeName'              name of the route, required if you want to link to this route with <router-link>
 * meta : {
 *    roles: ['admin','user','accountOwner']    will control the page roles (you can set multiple roles)
 *    permissionName: 'flow_view' | 'embed_view' | 'connector_view'  will control the page access according to the
 *                                                                   user's views access settings
 *    title: 'title'               the name that will be displayed in sub-menus, breadcrumbs, and page title
 *    noCache: false               if true, the page will not be cached (TODO: what 'cached' means exactly?)
 *  }
 * */

export default [

  /**
   * Misc
   */
  {
    path: '/:catchAll(.*)*',
    redirect: RouteName.NOT_FOUND,
  },
  {
    name: RouteName.NOT_FOUND,
    path: '/404',
    component: () => import(/* webpackChunkName: "core" */'@/ui/views/404'),
  },
  {
    name: RouteName.INDEX,
    path: '/',
    redirect: { name: RouteName.FLOW_MONITORING },
  },

  /**
   * Application routes
   */

  // Navbar user menu
  {
    name: RouteName.PROFILE,
    path: '/user-preferences',
    component: () => import(/* webpackChunkName: "profile" */'@/ui/views/Profile'),
    meta: {
      title: 'profile',
      noCache: true,
    },
  },
  {
    name: RouteName.COMPANY_PROFILE,
    path: '/company-preferences',
    component: () => import(/* webpackChunkName: "companyPreference" */'@/ui/views/CompanyPreference'),
    meta: {
      title: 'companyProfile',
      roles: ['admin', 'accountAdmin'],
      noCache: true,
    },
  },

  // Flow Monitoring
  {
    name: RouteName.FLOW_MONITORING,
    path: '/flow-monitoring',
    component: () => import(/* webpackChunkName: "flowMonitoring" */'@/ui/views/FlowMonitoring'),
    meta: {
      title: 'flowMonitoring',
      noCache: true,
      permissionName: 'flow_view',
    },
  },

  // Flows
  {
    name: RouteName.FLOWS_INDEX,
    path: '/flows',
    redirect: { name: RouteName.FLOWS },
    meta: {
      title: 'flows',
      noCache: true,
      permissionName: 'flow_view',
    },
    children: [
      {
        name: RouteName.FLOWS,
        path: '',
        component: () => import(/* webpackChunkName: "flowBuilder" */'@/ui/views/Flows'),
      },
      {
        path: ':flowid',
        redirect: 'noredirect',
        meta: {
          async title(params) {
            return (await flowApi.getName(params.flowid)).data.name
          },
        },
        children: [
          {
            path: '',
            redirect: { name: RouteName.FLOW_BUILDER },
          },
          {
            name: RouteName.FLOW_BUILDER,
            path: 'builder',
            component: () => import(/* webpackChunkName: "flowBuilder" */'@/ui/views/FlowBuilder'),
            meta: {
              title: 'flowBuilder',
              noCache: true,
              permissionName: 'flow_view',
            },
          },
          {
            name: RouteName.FLOW_DEBUGGER,
            path: 'debugger',
            component: () => import(/* webpackChunkName: "flowBuilder" */'@/ui/views/FlowDebugger'),
            meta: {
              title: 'flowdebugger',
              noCache: true,
              permissionName: 'flow_view',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/flowbuilder',
    redirect: { name: RouteName.FLOWS },
  },
  {
    path: '/flowbuilder/:flowid',
    redirect: { name: RouteName.FLOW_BUILDER },
  },
  {
    path: '/flowdebugger/:flowid',
    redirect: { name: RouteName.FLOW_DEBUGGER },
  },

  // Community Library
  {
    name: RouteName.COMMUNITY_LIBRARY,
    path: '/community-library',
    alias: '/flow-marketplace',
    component: () => import(/* webpackChunkName: "communityLibrary" */'@/ui/views/CommunityLibrary'),
    meta: {
      title: 'communityLibrary',
      permissionName: 'flow_view',
      noCache: true,
    },
  },

  // Connector Auth
  {
    name: RouteName.CONNECTOR_AUTH,
    path: '/connector-auth',
    component: () => import(/* webpackChunkName: "settings" */'@/ui/views/ConnectorAuth'),
    meta: {
      title: 'connectorAuth',
      noCache: true,
    },
  },

  // File Uploader
  {
    name: RouteName.FILE_UPLOAD,
    path: '/file-uploader',
    component: () => import(/* webpackChunkName: "settings" */'@/ui/views/FileUpload'),
    meta: {
      title: 'fileUploader',
      noCache: true,
    },
  },

  // Flow Env Variables
  {
    name: RouteName.ENV_VARIABLES,
    path: '/flow-env-variables',
    component: () => import(/* webpackChunkName: "settings" */'@/ui/views/EnvVariableList'),
    meta: {
      title: 'flowEnvVariables',
      noCache: true,
    },
  },

  // Activity Log
  {
    name: RouteName.ACTIVITY_LOG,
    path: '/activity-log',
    component: () => import(/* webpackChunkName: "settings" */'@/ui/views/ActivityLog'),
    meta: {
      title: 'activityLog',
      roles: ['admin', 'accountAdmin'],
      noCache: true,
    },
  },

  // Embed
  {
    path: '/embed',
    redirect: 'noredirect',
    meta: {
      title: 'embed',
      permissionName: 'embed_view',
    },
    children: [
      {
        path: 'configurations',
        name: RouteName.EMBEDDING_INDEX,
        redirect: { name: RouteName.EMBEDDING },
        meta: {
          title: 'configurations',
          noCache: true,
          permissionName: 'embed_view',
        },
        children: [
          {
            path: '',
            name: RouteName.EMBEDDING,
            component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding'),
          },
          {
            path: ':embedId',
            redirect: 'noredirect',
            meta: {
              async title(params) {
                const v2 = store.getters['feature/isEmbedV2Enabled']
                const api = v2 ? embeddedApiV2 : embeddedApiV1
                return (await api.getName(params.embedId)).data.name
              },
            },
            children: [
              // v1
              {
                path: 'groups',
                redirect: { name: RouteName.EMBED_GROUPS },
                name: RouteName.EMBED_GROUPS_INDEX,
                meta: {
                  title: 'Groups',
                  noCache: true,
                  permissionName: 'embed_view',
                },
                children: [
                  {
                    name: RouteName.EMBED_GROUPS,
                    path: '',
                    component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Groups'),
                    meta: {
                      noCache: true,
                      permissionName: 'embed_view',
                    },
                  },
                  {
                    path: ':groupId',
                    redirect: 'noredirect',
                    meta: {
                      async title(params) {
                        return (await embedGroupsApiV1(params.embedId).getName(params.groupId)).data.name
                      },
                    },
                    children: [
                      {
                        name: RouteName.EMBED_GROUP_FLOWS,
                        path: 'flows',
                        component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Groups/Flows'),
                        meta: {
                          title: 'Embed Flows',
                          noCache: true,
                          permissionName: 'embed_view',
                        },
                      },
                    ],
                  },
                ],
              },

              // v2
              {
                path: 'partners',
                redirect: { name: RouteName.EMBED_PARTNERS },
                name: RouteName.EMBED_PARTNERS_INDEX,
                meta: {
                  title: 'Partners',
                  noCache: true,
                  permissionName: 'embed_view',
                },
                children: [
                  {
                    name: RouteName.EMBED_PARTNERS,
                    path: '',
                    component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Partners'),
                    meta: {
                      noCache: true,
                      permissionName: 'embed_view',
                    },
                  },
                  {
                    path: ':partnerId',
                    redirect: 'noredirect',
                    meta: {
                      async title(params) {
                        return (await embedPartnerApiV2(params.embedId).getCategory(params.partnerId)).data.category
                      },
                    },
                    children: [
                      {
                        path: 'use-cases',
                        redirect: { name: RouteName.EMBED_USE_CASES },
                        name: RouteName.EMBED_USE_CASES_INDEX,
                        meta: {
                          title: 'Use Cases',
                          noCache: true,
                          permissionName: 'embed_view',
                        },
                        children: [
                          {
                            name: RouteName.EMBED_USE_CASES,
                            path: '',
                            component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Partners/UseCases'),
                            meta: {
                              noCache: true,
                              permissionName: 'embed_view',
                            },
                          },
                          {
                            path: ':useCaseId',
                            redirect: 'noredirect',
                            meta: {
                              async title(params) {
                                return (await embedUseCaseApiV2(params.embedId, params.partnerId).getName(params.useCaseId)).data.name
                              },
                            },
                            children: [
                              {
                                name: RouteName.EMBED_USE_CASE_FLOWS,
                                path: 'flows',
                                component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/Groups/Flows'),
                                meta: {
                                  title: 'Embed Flows',
                                  noCache: true,
                                  permissionName: 'embed_view',
                                },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: RouteName.EMBEDDING_INTEGRATIONS,
        path: 'integrations',
        component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/EmbeddingIntegrations'),
        meta: {
          title: 'integrations',
          noCache: true,
          permissionName: 'embed_view',
        },
      },
      {
        name: RouteName.EMBEDDING_CONNECTOR_AUTHS,
        path: 'connector-auth',
        component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/EmbeddingConnectorAuths'),
        meta: {
          title: 'connectorAuth',
          noCache: true,
          permissionName: 'embed_view',
        },
      },
      /** Previously this was the path of activity-log, we want to deprecate this eventually... */
      {
        path: '/embedding/embedding/activity-log',
        redirect: { name: RouteName.EMBEDDING_ACTIVITY_LOG },
      },
      {
        name: RouteName.EMBEDDING_ACTIVITY_LOG,
        path: 'activity-log',
        component: () => import(/* webpackChunkName: "embedding" */'@/ui/views/Embedding/EmbeddingActivityLog'),
        meta: {
          title: 'activityLog',
          noCache: true,
          permissionName: 'embed_view',
        },
      },
      // Redirects /embedding/* to /embed/*, preserving the rest of the path
      {
        path: '/embedding/:pathMatch(.*)*',
        redirect: (to) => to.fullPath.replace('/embedding', '/embed'),
      },
    ],
  },

  // Dashboards
  {
    path: '/dashboard',
    redirect: 'noredirect',
    meta: {
      title: 'myDashboards',
      icon: 'dashboard',
      permissionName: 'dashboard_view',
    },
    children: [
      {
        name: RouteName.DASHBOARD_TRANSFORMATION,
        path: 'transformation',
        component: () => import(/* webpackChunkName: "transformation" */'@/ui/views/Dashboards/Transformation'),
        meta: {
          title: 'transformation',
          icon: 'component',
          noCache: true,
          permissionName: 'dashboard_view',
        },
      },
      {
        name: RouteName.DASHBOARD_DATA_SOURCE,
        path: 'data-source',
        component: () => import(/* webpackChunkName: "dataSource" */'@/ui/views/Dashboards/DataSource'),
        meta: {
          title: 'dataSources',
          icon: 'list',
          noCache: true,
          permissionName: 'dashboard_view',
        },
      },
    ],
  },

  // Settings
  {
    path: '/settings',
    redirect: 'noredirect',
    meta: {
      title: 'settings',
      icon: 'form',
    },
    children: [
      {
        path: 'connector',
        name: RouteName.CONNECTOR_INDEX,
        redirect: { name: RouteName.CONNECTOR },
        meta: {
          title: 'connector',
          icon: 'international',
          noCache: true,
          permissionName: 'connector_view',
        },
        children: [
          {
            name: RouteName.CONNECTOR,
            path: '',
            component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/Connector'),
            meta: {
              noCache: true,
              permissionName: 'connector_view',
            },
          },
          {
            path: ':connectorId',
            redirect: 'noredirect',
            meta: {
              async title(params) {
                return (await connectorApi.getName(params.connectorId)).data.name
              },
            },
            children: [
              {
                name: RouteName.CONNECTOR_ACTIONS,
                path: 'action',
                component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/ConnectorAction'),
                meta: {
                  title: 'connectorActions',
                  noCache: true,
                  permissionName: 'connector_view',
                },
              },
            ],
          },
        ],
      },
      {
        name: RouteName.USERS,
        path: 'user',
        component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/User'),
        meta: {
          title: 'user',
          roles: ['admin', 'accountAdmin'],
          icon: 'user',
          noCache: true,
        },
      },
      {
        name: RouteName.TEAMS,
        path: 'team',
        component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/Team'),
        meta: {
          title: 'team',
          roles: ['admin', 'accountAdmin'],
          icon: 'peoples',
          noCache: true,
        },
      },
      {
        name: RouteName.COMPANY,
        path: 'company',
        component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/Company'),
        meta: {
          title: 'company',
          roles: ['admin'],
          elIcon: 'ElIconOfficeBuilding',
          noCache: true,
        },
      },
      {
        name: RouteName.ROLES,
        path: 'roles',
        component: () => import(/* webpackChunkName: "settings" */'@/ui/views/Settings/Roles'),
        meta: {
          title: 'roles',
          roles: ['admin'],
          elIcon: 'ElIconLock',
          noCache: true,
        },
      },
    ],
  },

  // Help & Docs
  {
    path: '/help-and-doc-router',
    redirect: { name: RouteName.FLOW_BUILDER },
    meta: {
      title: 'help_docs',
      icon: 'example',
      data: [
        {
          path: 'https://docs.locoia.com/',
          meta: {
            title: 'documentation',
            icon: 'documentation',
            noCache: true,
          },
        },
        {
          path: 'https://www.youtube.com/channel/UC4WaSwSERFd3K-v7-sik4fw',
          meta: {
            title: 'YouTube',
            elIcon: 'ElIconVideoCamera',
            noCache: true,
          },
        },
        {
          path: '',
          meta: {
            title: 'Shortcuts',
            dialog: 'Shortcuts',
            elIcon: 'ElIconInfoFilled',
            event: 'shortcut',
            noCache: true,
          },
        },
        {
          path: 'https://join.slack.com/t/locoia-automation/shared_invite/zt-xnphvr1d-_eI0E9_1VGCndrBnFyRPzg',
          meta: {
            title: 'Community Slack',
            icon: 'peoples',
            noCache: true,
          },
        },
        {
          path: 'https://www.locoia.com',
          meta: {
            title: 'About us',
            elIcon: 'ElIconOfficeBuilding',
            noCache: true,
          },
        },
        {
          path: 'https://hubs.ly/Q01bf7tf0',
          meta: {
            title: 'GTCs',
            icon: 'documentation',
            noCache: true,
          },
        },
      ],
    },
  },
]
