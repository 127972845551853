import { createRouter, createWebHistory, isNavigationFailure, NavigationFailureType } from 'vue-router'
import routes from '@/ui/router/routes'
import { hash, pageTitle, progress } from '@/ui/router/guards'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior: () => ({ top: 0 }),
  routes,
})

// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = router.push
router.push = (to) => originalPush.call(router, to).catch((err) => {
  if (isNavigationFailure(err, NavigationFailureType.redirected)) return
  throw err
})

progress(router)
pageTitle(router)
hash(router)

export default router
