<template>
  <div class="authenticator-container">
    <authenticator
      :services="services"
      :form-fields="authenticatorFormFields"
      :hide-sign-up="true"
    >
      <template #header>
        <img class="logo" src="@/ui/assets/images/locoia-logo.png">
      </template>
      <template v-if="auth" #confirm-sign-in-header>
        <h3 class="amplify-heading amplify-heading--3">{{ i18n.t('auth.confirmSignIn.confirmSmsCode') }}</h3>
        <div>
          {{ i18n.t('auth.confirmSignIn.verificationCodeSentTo', { phoneNumber: getHiddenMfaPhoneNumber() }) }}
        </div>
      </template>
    </authenticator>
  </div>
</template>

<script>
import { I18n } from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue'
import { AuthenticatorServiceFacade } from '@aws-amplify/ui'
import { Hub } from 'aws-amplify'
import { currentLanguage } from '@/ui/lang'
import amplifyI18nSnippetsDe from '@/ui/lang/de/amplify'
import amplifyI18nSnippetsEn from '@/ui/lang/en/amplify'

I18n.setLanguage(currentLanguage.value)

const dict = {
  de: amplifyI18nSnippetsDe,
  en: amplifyI18nSnippetsEn,
}
I18n.putVocabularies(dict)

export default {
  name: 'Login',
  components: {
    Authenticator,
  },
  inject: ['i18n'],
  emits: ['login-successful'],
  data() {
    return {
      /** Most of the time it will be of this type, but during MFA some properties are added/change
       * The library does not provide a type for this.
       * @type AuthenticatorServiceFacade
      */
      auth: null,
      removeAuthListener: () => { },
      services: {
        /** To wait for the user data and redirect after authentication */
        handleSignIn: async (formData) => {
          const user = await Auth.signIn(formData.username, formData.password)
          /** `Auth.currentAuthenticatedUser()` returns ResourceNotFoundException if called during this time. */
          const isChangingTemporaryPassword = user.challengeName === 'NEW_PASSWORD_REQUIRED'
          if (!isChangingTemporaryPassword) {
            await this.findUser()
          }
          return user
        },
        /** To wait for the user data and redirect after MFA. */
        handleConfirmSignIn: async (formData) => {
          const user = await Auth.confirmSignIn(formData.user, formData.code, formData.mfaType)
          await this.findUser()
          return user
        },
      },
      /** Sign up is working, but hidden */
      authenticatorFormFields: {
        signUp: {
          given_name: {
            label: this.i18n.t('auth.signUp.firstName'),
            placeholder: this.i18n.t('auth.signUp.firstName'),
            order: 1,
          },
          family_name: {
            label: this.i18n.t('auth.signUp.lastName'),
            placeholder: this.i18n.t('auth.signUp.lastName'),
            order: 2,
            required: true,
          },
          email: {
            label: this.i18n.t('auth.signUp.email'),
            placeholder: this.i18n.t('auth.signUp.email'),
            order: 3,
            required: true,
          },
          username: {
            label: this.i18n.t('auth.signUp.username'),
            placeholder: this.i18n.t('auth.signUp.username'),
            order: 4,
            required: true,
          },
          phone_number: {
            label: this.i18n.t('auth.signUp.phoneNumber'),
            placeholder: this.i18n.t('auth.signUp.phoneNumber'),
            order: 5,
            required: true,
          },
          password: {
            label: this.i18n.t('auth.signUp.password'),
            placeholder: this.i18n.t('auth.signUp.password'),
            order: 6,
            required: true,
          },
        },
      },
    }
  },
  created() {
    this.auth = useAuthenticator()
    this.removeAuthListener = Hub.listen('auth', (data) => {
      /**
       * We need this to be able to redirect on the first signIn after the temporary password change
       * This is because the ui does not support a handleChangePassword yet https://github.com/aws-amplify/amplify-ui/issues/3532
      */
      if (this.auth.route === 'forceNewPassword') {
        this.auth.toSignIn()
      }
    })
  },
  mounted() {
    this.preventWhitespaceInputs()
    this.findUser()
  },
  unmounted() {
    this.removeAuthListener()
  },
  methods: {
    async findUser() {
      try {
        const isLoggedIn = !!(await this.$store.dispatch('user/getAuthenticatedUserToken'))
        if (!isLoggedIn) return
        this.$emit('login-successful')
      } catch (error) {
        // Network error, invalid user authentication or error in creating user object
      }
    },
    preventWhitespaceInputs() {
      if (!this.$refs.authenticator?.$el) return
      const inputs = this.$refs.authenticator.$el.querySelectorAll('input')
      const createPreventFunction = (input) => () => {
        input.value = input.value.trim()
      }
      inputs.forEach((input) => {
        const preventFunction = createPreventFunction(input)
        input.addEventListener('input', preventFunction)
        input.addEventListener('change', preventFunction)
      })
    },
    /** During MFA `auth.user` has more properties.
     * `challengeParam` being one of them that defines the choice of MFA, in our case phone number.
    */
    getHiddenMfaPhoneNumber() {
      const userNumber = this.auth.user?.challengeParam?.CODE_DELIVERY_DESTINATION
      if (!userNumber) return this.i18n.t('auth.confirmSignIn.unknownNumber')
      return userNumber
    },
  },
}
</script>

<style lang="scss" scoped>
.authenticator-container {
  padding: 50px 0px;

  :deep(.amplify-button[data-variation='primary']) {
    background: #19469f;
  }

  :deep(.amplify-button[data-variation='link']) {
    color: #19469f;
  }

  :deep(.amplify-button[data-variation='link']:hover) {
    background: #326acc;
    color: white;
  }

  :deep(.amplify-field-group .amplify-input[name="username"]) {
    border-start-start-radius: var(--amplify-components-fieldcontrol-border-radius);
    border-end-start-radius: var(--amplify-components-fieldcontrol-border-radius);
  }
}

.logo {
  height: 45px;
  margin: 50px 0;
}
</style>
