<template>
  <el-scrollbar class="scrollbar">
    <el-breadcrumb separator=">" class="breadcrumb">
      <el-breadcrumb-item v-for="breadcrumb in breadcrumbs" :key="breadcrumb.path" class="breadcrumb-item">
        <span v-if="breadcrumb.redirect === 'noredirect'">
          {{ breadcrumb.title }}
        </span>
        <!--
          We need to use this hacky solution with the <a> tag, as <router-link> is not working here
          => https://github.com/vuejs/router/issues/845
        -->
        <a
          v-else
          @click="$router.push({
            name: breadcrumb.name,
            params: $route.params
          })"
        >
          {{ breadcrumb.title }}
        </a>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </el-scrollbar>
</template>

<script>
import { generateTitle } from '@/ui/utils/i18n'
import * as R from 'ramda'

export default {
  name: 'Breadcrumb',
  data() {
    return {
      /** @type {Array<object>} */
      breadcrumbs: [],
    }
  },
  computed: {
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
  },
  watch: {
    $route(newRoute, previousRoute) {
      const hasChangedPage = previousRoute.name !== newRoute.name
      const hasChangedParams = !R.equals(previousRoute.params, newRoute.params)
      if (hasChangedPage || hasChangedParams) {
        this.setBreadcrumbs()
      }
    },
  },
  created() {
    this.setBreadcrumbs()
  },
  methods: {
    async setBreadcrumbs() {
      const matchedRoutes = this.$route.matched.filter((item) => item.meta && item.meta.title)

      const promises = []
      for (const route of matchedRoutes) {
        const meta = route.meta ?? {}
        if (!meta.title) continue

        let titleFunc = meta.title
        if (typeof meta.title !== 'function') {
          titleFunc = async _ => generateTitle(meta.title)
        }

        const promise = async () => ({
          name: route.name,
          title: await titleFunc(this.$route.params),
          redirect: route.redirect,
        })
        promises.push(promise())
      }

      this.breadcrumbs = await Promise.all(promises)
    },
  },
}
</script>

<style lang="scss" scoped>
  .scrollbar {
    width: calc(
      100%
      - 8px   // margin-left
      - 8px   // margin-right
      - 113px // right-menu
      - 50px  // hamburger
    );
  }

  .breadcrumb {
    display: flex;
    font-size: 18px;
    font-weight: 500;

    .breadcrumb-item {
      flex-shrink: 0;
      display: flex;
      height: 50px;
    }
  }
</style>
