import { buildApi, requestGet, requestPost } from '@/ui/utils/request'

export const embeddedApi = {
  ...buildApi('/v1/embedded-configurations'),
  getName: id => embeddedApi.get(id, { withFields: ['name'] }),
  listEmbedIntegrations: options => requestGet('/v1/embedded-integrations', options),
  rerunEmbedIntegrations: integrationIds => requestPost('/v1/embedded-integrations/rerun', { data: { integration_ids: integrationIds }}),
  disableEmbedIntegrations: integrationIds => requestPost('/v1/embedded-integrations/disable', { data: { integration_ids: integrationIds }}),
  listConnectorAuths: options => requestGet('/v1/embedded-connector-auths', options),
  listActivityLogs: options => requestGet('/v1/embedded-activity-logs', options),
}

export const embedGroupsApi = (embedId) => ({
  ...buildApi(`/v1/embedded-configurations/${embedId}/groups`),
  getName: id => embedGroupsApi(embedId).get(id, { withFields: ['name'] }),
})

export const embedGroupFlowsApi = (embedId, groupId) => ({
  ...buildApi(`/v1/embedded-configurations/${embedId}/groups/${groupId}/flows`),
})
