import { buildApi } from '@/ui/utils/request'

export const embeddedApi = {
  ...buildApi('/v2/embedded-configurations'),
  getName: id => embeddedApi.get(id, { withFields: ['name'] }),
}

export const embedPartnerApi = (embedId) => ({
  ...buildApi(`/v2/embedded-configurations/${embedId}/partners`),
  getCategory: id => embedPartnerApi(embedId).get(id, { withFields: ['category'] }),
})

export const embedUseCaseApi = (embedId, partnerId) => ({
  ...buildApi(`/v2/embedded-configurations/${embedId}/partners/${partnerId}/use-cases`),
  getName: id => embedUseCaseApi(embedId, partnerId).get(id, { withFields: ['name'] }),
})

export const embedUseCaseFlowsApi = (embedId, partnerId, useCaseId) => ({
  ...buildApi(`/v2/embedded-configurations/${embedId}/partners/${partnerId}/use-cases/${useCaseId}/flows`),
  getName: id => embedUseCaseFlowsApi(embedId, partnerId, useCaseId).get(id, { withFields: ['name'] }),
})
