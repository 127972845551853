import ElementPlus from 'element-plus'
import { createApp } from 'vue'
import SchemaFormRendererPlugin from '@/ui-libs/schema-form-renderer'
import App from '@/ui/App'
import Icon from '@/ui/icons'
import { i18n, currentLanguage } from '@/ui/lang'
import amplify from '@/ui/plugins/amplify'
import icons from '@/ui/plugins/icons'
import permission from '@/ui/plugins/permission'
import sentry from '@/ui/plugins/sentry'
import router from '@/ui/router'
import store from '@/ui/store'
import 'normalize.css/normalize.css'
import 'element-plus/dist/index.css'
import 'splitpanes/dist/splitpanes.css'
import '@aws-amplify/ui-vue/styles.css'
import '@/ui-libs/syncfusion/syncfusion-material.css'
import '@/ui/styles/index.scss'
// TODO: this import is here solely to run side effects in its index.js, find a better way to do this
import '@/ui/api'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(i18n)
app.provide('i18n', i18n.global)
app.use(SchemaFormRendererPlugin, { language: currentLanguage })
app.use(amplify)
app.use(icons)
app.use(permission)
app.use(sentry)

app.component('Icon', Icon)

router.isReady().then(() => app.mount('#app'))

window.app = app

export { app }
