import * as R from 'ramda'
import i18n from '@/ui/lang'
import router from '@/ui/router'

export const setPageTitle = (route, { appendTitle } = {}) => {
  let title = ''
  if (appendTitle || route?.meta?.appendTitle) {
    title += `${appendTitle || route.meta.appendTitle} - `
  }
  if (route?.meta?.title) {
    // eslint-disable-next-line sonarjs/no-nested-template-literals
    title += `${i18n.global.t(`route.${route.meta.title}`)} @ `
  }
  title += 'Locoia'

  document.title = title
}

/* eslint-disable-next-line sonarjs/cognitive-complexity */
export const queryParamsToRequestParams = (queryParams, requestParam) => {
  const newParams = R.clone(queryParams)

  if (newParams.nameSearchString === '') {
    newParams.nameSearchString = null
  }

  if (newParams.searchStepQuery === '') {
    newParams.searchStepQuery = null
  }

  if (newParams.endpointSearchString === '') {
    newParams.endpointSearchString = null
  }

  if (newParams.context === '') {
    newParams.context = null
  }

  if (newParams.includeEmbedded && typeof newParams.includeEmbedded === 'string') {
    newParams.includeEmbedded = newParams.includeEmbedded === 'true'
  }

  if (newParams.page) {
    newParams.page = Number(newParams.page) || requestParam.page
  }

  if (newParams.perPage) {
    newParams.perPage = Number(newParams.perPage) || requestParam.perPage
  }

  if (newParams.flowRunsPage) {
    newParams.flowRunsPage = Number(newParams.flowRunsPage) || requestParam.flowRunsPage
  }

  if (newParams.flowRunsStepPage) {
    newParams.flowRunsStepPage = Number(newParams.flowRunsStepPage) || requestParam.flowRunsStepPage
  }

  if (newParams.tags) {
    newParams.selectedTags = newParams.tags.split(',')
  }

  const hasflowRunStepsParentsPages = !R.isNil(newParams.flowRunStepsParentsPages) && !R.isEmpty(newParams.flowRunStepsParentsPages)
  if (hasflowRunStepsParentsPages) {
    newParams.flowRunStepsParentsPages = newParams.flowRunStepsParentsPages.split(',').map(Number)
  }

  if (newParams.sortProp || newParams.sortOrder) {
    newParams.sort = {}

    if (newParams.sortProp) {
      newParams.sort.prop = newParams.sortProp
    }

    if (newParams.sortOrder) {
      newParams.sort.order = newParams.sortOrder
    }
  }

  const hasUserIds = !R.isNil(newParams.userIds) && !R.isEmpty(newParams.userIds)
  if (hasUserIds) {
    newParams.userIds = newParams?.userIds.split(',')
  }

  const hasCreatedByUserIds = !R.isNil(newParams.createdByUserIds) && !R.isEmpty(newParams.createdByUserIds)
  if (hasCreatedByUserIds) {
    newParams.createdByUserIds = newParams?.createdByUserIds.split(',')
  }

  const hasUpdatedByUserIds = !R.isNil(newParams.updatedByUserIds) && !R.isEmpty(newParams.updatedByUserIds)
  if (hasUpdatedByUserIds) {
    newParams.updatedByUserIds = newParams?.updatedByUserIds.split(',')
  }

  const hasHttpMethods = !R.isNil(newParams.httpMethods) && !R.isEmpty(newParams.httpMethods)
  if (hasHttpMethods) {
    newParams.httpMethods = newParams?.httpMethods.split(',')
  }

  const hasActivityTypes = !R.isNil(newParams.activityTypes) && !R.isEmpty(newParams.activityTypes)
  if (hasActivityTypes) {
    newParams.activityTypes = newParams?.activityTypes.split(',')
  }

  const hasflowRunStatuses = !R.isNil(newParams.flowRunsStatuses) && !R.isEmpty(newParams.flowRunsStatuses)
  if (hasflowRunStatuses) {
    newParams.flowRunsStatuses = newParams.flowRunsStatuses.split(',')
  }

  const hasflowRunTriggers = !R.isNil(newParams.flowRunsTriggers) && !R.isEmpty(newParams.flowRunsTriggers)
  if (hasflowRunTriggers) {
    newParams.flowRunsTriggers = newParams.flowRunsTriggers.split(',')
  }

  const hasflowRunStepStatuses = !R.isNil(newParams.flowRunStepsStatuses) && !R.isEmpty(newParams.flowRunStepsStatuses)
  if (hasflowRunStepStatuses) {
    newParams.flowRunStepsStatuses = newParams.flowRunStepsStatuses.split(',')
  }

  const hasFlowRunsStartDateInterval = !R.isNil(newParams.flowRunsStartDateInterval) && !R.isEmpty(newParams.flowRunsStartDateInterval)
  if (hasFlowRunsStartDateInterval) {
    newParams.flowRunsStartDateInterval = newParams.flowRunsStartDateInterval.split(',').map(date => new Date(date))
  }

  const hasflowRunStepsStartDateInterval = !R.isNil(newParams.flowRunStepsStartDateInterval) && !R.isEmpty(newParams.flowRunStepsStartDateInterval)
  if (hasflowRunStepsStartDateInterval) {
    newParams.flowRunStepsStartDateInterval = newParams.flowRunStepsStartDateInterval.split(',').map(date => new Date(date))
  }

  const hasCreatedDateInterval = !R.isNil(newParams.createdDateInterval) && !R.isEmpty(newParams.createdDateInterval)
  if (hasCreatedDateInterval) {
    newParams.createdDateInterval = newParams.createdDateInterval.split(',').map(date => new Date(date))
  }

  return R.omit(
    [
      'tags',
      'sortProp',
      'sortOrder',
    ],
    { ...requestParam, ...newParams },
  )
}

/* eslint-disable-next-line sonarjs/cognitive-complexity */
export const requestParamsToQueryParams = (requestParam) => {
  const queryParams = R.clone(requestParam)

  if (requestParam.nameSearchString === '') {
    delete queryParams.nameSearchString
  }

  if (requestParam.searchStepQuery === '') {
    delete queryParams.searchStepQuery
  }

  if (R.isNil(requestParam.endpointSearchString) || R.isEmpty(requestParam.endpointSearchString)) {
    delete queryParams.endpointSearchString
  }

  if (R.isNil(requestParam.context) || R.isEmpty(requestParam.context)) {
    delete queryParams.context
  }

  if (requestParam.selectedTags && !R.isEmpty(requestParam.selectedTags)) {
    queryParams.tags = requestParam?.selectedTags.join(',')
  }

  if (requestParam.flowRunStepsParentsPages && !R.isEmpty(requestParam.flowRunStepsParentsPages)) {
    queryParams.flowRunStepsParentsPages = requestParam?.flowRunStepsParentsPages.join(',')
  }

  if (R.has('includeEmbedded')(requestParam)) {
    queryParams.includeEmbedded = String(requestParam.includeEmbedded)
  }

  if (requestParam.sort) {
    queryParams.sortProp = requestParam.sort?.prop
    queryParams.sortOrder = requestParam.sort?.order
  }

  if (requestParam.page) {
    queryParams.page = String(requestParam.page) || requestParam.page
  }

  if (requestParam.perPage) {
    queryParams.perPage = String(requestParam.perPage) || requestParam.perPage
  }

  if (requestParam.flowRunsPage) {
    queryParams.flowRunsPage = String(requestParam.flowRunsPage) || requestParam.flowRunsPage
  }

  if (requestParam.flowRunsStepPage) {
    queryParams.flowRunsStepPage = String(requestParam.flowRunsStepPage) || requestParam.flowRunsStepPage
  }

  const hasUserIds = !R.isNil(requestParam.userIds) && !R.isEmpty(requestParam.userIds)
  if (hasUserIds) {
    queryParams.userIds = requestParam?.userIds.join(',')
  }

  const hasCreatedByUserIds = !R.isNil(requestParam.createdByUserIds) && !R.isEmpty(requestParam.createdByUserIds)
  if (hasCreatedByUserIds) {
    queryParams.createdByUserIds = requestParam.createdByUserIds.join(',')
  }

  const hasUpdatedByUserIds = !R.isNil(requestParam.updatedByUserIds) && !R.isEmpty(requestParam.updatedByUserIds)
  if (hasUpdatedByUserIds) {
    queryParams.updatedByUserIds = requestParam?.updatedByUserIds.join(',')
  }

  const hasHttpMethods = !R.isNil(requestParam.httpMethods) && !R.isEmpty(requestParam.httpMethods)
  if (hasHttpMethods) {
    queryParams.httpMethods = requestParam?.httpMethods.join(',')
  }

  const hasActivityTypes = !R.isNil(requestParam.activityTypes) && !R.isEmpty(requestParam.activityTypes)
  if (hasActivityTypes) {
    queryParams.activityTypes = requestParam?.activityTypes.join(',')
  }

  const hasflowRunsStatuses = !R.isNil(requestParam.flowRunsStatuses) && !R.isEmpty(requestParam.flowRunsStatuses)
  if (hasflowRunsStatuses) {
    queryParams.flowRunsStatuses = requestParam?.flowRunsStatuses.join(',')
  }

  const hasflowRunsTriggers = !R.isNil(requestParam.flowRunsTriggers) && !R.isEmpty(requestParam.flowRunsTriggers)
  if (hasflowRunsTriggers) {
    queryParams.flowRunsTriggers = requestParam?.flowRunsTriggers.join(',')
  }

  const hasflowRunStepsStatuses = !R.isNil(requestParam.flowRunStepsStatuses) && !R.isEmpty(requestParam.flowRunStepsStatuses)
  if (hasflowRunStepsStatuses) {
    queryParams.flowRunStepsStatuses = requestParam?.flowRunStepsStatuses.join(',')
  }

  const hasFlowRunsStartDateInterval = !R.isNil(requestParam.flowRunsStartDateInterval) && !R.isEmpty(requestParam.flowRunsStartDateInterval)
  if (hasFlowRunsStartDateInterval) {
    queryParams.flowRunsStartDateInterval = requestParam.flowRunsStartDateInterval.join(',')
  }

  const hasFlowRunStepsStartDateInterval = !R.isNil(requestParam.flowRunStepsStartDateInterval) && !R.isEmpty(requestParam.flowRunStepsStartDateInterval)
  if (hasFlowRunStepsStartDateInterval) {
    queryParams.flowRunStepsStartDateInterval = requestParam.flowRunStepsStartDateInterval.join(',')
  }

  const hasCreatedDateInterval = !R.isNil(requestParam.createdDateInterval) && !R.isEmpty(requestParam.createdDateInterval)
  if (hasCreatedDateInterval) {
    queryParams.createdDateInterval = requestParam.createdDateInterval.join(',')
  }
  return R.omit(['selectedTags', 'sort'], queryParams)
}

/** Deletes the "dialog" query param which is used to show a dialog immediately upon opening a page.
 * We want to delete it so that revisiting the page or refreshing it after closing the dialog, won't open the dialog again.
 */
export async function deleteDialogQueryParam(route) {
  const query = { ...route.query }
  delete query.dialog
  delete query.dialogEntityId
  await router.replace({
    ...route,
    query,
  })
}
