<template>
  <div
    v-if="!isVisible"
    @click="isVisible = true"
  >
    <icon icon="user" />
  </div>
  <el-dropdown
    v-else
    ref="dropdown"
    trigger="click"
    placement="bottom-end"
    @visible-change="isVisible = $event"
  >
    <div>
      <icon icon="user" />
    </div>
    <template #dropdown>
      <el-dropdown-menu class="dropdown">
        <router-link to="/user-preferences">
          <el-dropdown-item class="dropdown__item">
            User - {{ userCustomData.email }}
          </el-dropdown-item>
        </router-link>
        <router-link
          v-if="$hasPermission(['admin', 'accountAdmin'])"
          :to="{ name: RouteName.COMPANY_PROFILE }"
          class="link-type"
        >
          <el-dropdown-item class="dropdown__item">
            {{ `${i18n.t('basic.company')} - ${companyName || ''}` }}
          </el-dropdown-item>
        </router-link>
        <a target="_blank" href="https://docs.locoia.com">
          <el-dropdown-item class="dropdown__item" divided>
            {{ i18n.t('navbar.support') }}
          </el-dropdown-item>
        </a>
        <a target="_blank" href="https://status.locoia.com/">
          <el-dropdown-item class="dropdown__item">
            {{ i18n.t('navbar.systemstatus') }}
          </el-dropdown-item>
        </a>
        <el-dropdown-item class="dropdown__item" divided @click="logout">
          <span style="display:block;" data-test="logout">{{ i18n.t('navbar.logOut') }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { RouteName } from '@/ui/router/consts'

export default {
  name: 'UserDropdown',
  inject: ['i18n'],
  data() {
    return {
      isVisible: false,
      RouteName,
    }
  },
  computed: {
    ...mapState({
      companyName: (state) => state.company?.company?.name,
      sidebar: (state) => state.app?.sidebar,
    }),
    ...mapGetters({
      userCustomData: 'user/customData',
    }),
  },
  watch: {
    isVisible() {
      this.$nextTick(() => {
        if (!this.isVisible) return
        this.$refs.dropdown.handleOpen()
      })
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('user/LogOut')
    },
  },
}
</script>
