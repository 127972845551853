export default {
  add: 'Hinzufügen',
  clickToUpload: 'Klicken Sie zum Hochladen',
  delete: 'Löschen',
  enableRemoteSearchAuthorization: 'Autorisierung für die entfernte Suche aktivieren',
  error: {
    error: 'Fehler',
    expandListSchema: 'Es gab ein Problem mit dem expand_list Schema.',
    fetchingRemoteData: 'Es gab einen Fehler beim Abrufen von Remote-Daten',
    unableToProvideSuggestions: 'Vorschläge können nicht bereitgestellt werden',
  },
  form: {
    endDate: 'Enddatum',
    startDate: 'Startdatum',
    toPreposition: 'Bis',
  },
  learnMore: 'Mehr erfahren ...',
  submit: 'Absenden',
  submitRegardless: 'Unabhängig absenden',
  uploadLimit: 'Das Hochladen ist auf 1 Datei pro Referenz begrenzt.',
  validation: {
    failed: 'Validierung fehlgeschlagen',
    isRequired: '{property} ist erforderlich',
    maxLength: '{property} sollte nicht länger als {maxLength} sein',
    minLength: '{property} sollte mindestens eine Länge von {minLength} haben',
  },
}
