import { buildApi, requestDelete, requestGet, requestPost, requestPut } from '@/ui/utils/request'

const reducedFlowFields = ['id', 'name', 'description', 'updated_date', 'created_date', 'status', 'updated_by']

const settingsFlowFields = [
  'id', 'name', 'status', 'description', 'tags', 'error_handling_flow_id', 'flow_data_saved',
  'notify_email', 'automatic_retries', 'webhook_token', 'webhook_token_enabled',
]

const settingsEmbedFields = ['rights', 'visibility']

export const flowApi = {
  ...buildApi('/v1/flows'),
  listForMonitoring: options => flowApi.list({ perPage: 25, sort: [['updated_date', 'desc']], withFields: reducedFlowFields }),
  listTemplates: options => requestGet('/v1/flows/templates', { withFields: reducedFlowFields, ...options }),
  getName: id => flowApi.get(id, { withFields: ['name'] }),
  getSettings: id => flowApi.get(id, { embed: settingsEmbedFields, withFields: settingsFlowFields }),
  /**
   * @param {Flow} flow
   * @param {FlowRunOptionsForm} [flowRunOptions]
   */
  run: async (flow, flowRunOptions) => {
    const userInput = {
      form_data: flowRunOptions.formHelperData,
      webhook_payload: flowRunOptions.webhookPayload,
    }

    return requestPost(`/v1/flows/${flow.id}/run`, { data: userInput })
  },
}

export const bulkFlowApi = {
  update: data => requestPut('/v1/flows/bulk', { data }),
  delete: flowIds => requestDelete('/v1/flows/bulk', { params: { flowIds: flowIds.join(',') }}),
}
