export default {
  roles: 'Rollen',
  switchRoles: 'Rolle wechseln',
  tips: 'In einigen Fällen ist die Verwendung von v-permission nicht geeignet, z. B. Element Tab-Komponente oder el-table-column und andere asynchrone Render-Dom-Cases, die nur durch manuelles Setzen des v-if erreicht werden können.',
  create: 'Darf erstellen',
  read: 'Darf sehen',
  run: 'Darf ausführen',
  write: 'Darf bearbeiten',
  delete: 'Darf löschen',
  manage: 'Darf Rechte verwalten',
  add: 'Hinzufügen',
  sharingSettings: 'Freigabeeinstellungen',
  justMe: 'Nur für mich',
  everyone: 'Alle',
  selectedUsersAndTeams: 'Ausgewählte Nutzer und Teams',
  visibleTo: 'Sichtbar für:',
  share: 'Freigabe',
  type: 'Typ',
  name: 'Name',
  permission: 'Berechtigung',
  team: 'Team',
  user: 'Nutzer',
  company: 'Unternehmen',
  entireCompany: 'Gesamtes Unternehmen',
  thisCompany: 'Aktuelles Unternehmen',
  namePlaceholder: 'Name des Teams/Mitarbeiters',
  apply: 'Aktivieren',
  cancel: 'Abbrechen',
  permissionAlreadyExists: 'Die Berechtigung \'{action}\' existiert bereits für \'{principal}\'',
  ensureAtLeastOneManage: 'Bitte stellen Sie sicher, dass die {resource} mindestens eine Manager-Berechtigung hat.',
  ensureAtLeastOneManageForBulk: 'Bitte stellen Sie sicher, dass die neuen Berechtigungen mindestens eine Manager-Berechtigung enthalten.',
  flowPermissions: 'Flow-Berechtigungen',
  dashboardPermissions: 'Dashboard-Berechtigungen',
  connectorPermissions: 'Connector-Berechtigungen',
  embedPermissions: 'Embed-Berechtigungen',
  internalPublic: 'Global öffentlich (für Locoia Kunden)',
  visiblityDetails: 'Individuelle Sharing-Einstellung:',
  of: 'von',
}
