import Cookies from 'js-cookie'
import { LANGUAGE_KEY } from '@/ui/consts'
import { currentLanguage } from '@/ui/lang'
import { locoiaLocalStorage, getLocalStorageKey } from '@/ui/store/localStorage'

export default {
  namespaced: true,
  state: () => ({
    sidebar: {
      opened: locoiaLocalStorage.getItem(getLocalStorageKey.sidebarOpened()) ?? false,
      withoutAnimation: false,
    },
    device: 'desktop',
    language: currentLanguage,
    size: Cookies.get('size') || 'medium',
  }),
  actions: {
    toggleSideBar({ commit }) {
      commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({ commit }, { withoutAnimation }) {
      commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice({ commit }, device) {
      commit('TOGGLE_DEVICE', device)
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
  },
  mutations: {
    TOGGLE_SIDEBAR: (state) => {
      state.sidebar.opened = !state.sidebar.opened
      state.sidebar.withoutAnimation = false
      locoiaLocalStorage.setItem(getLocalStorageKey.sidebarOpened(), state.sidebar.opened)
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      state.sidebar.opened = false
      state.sidebar.withoutAnimation = withoutAnimation
      locoiaLocalStorage.setItem(getLocalStorageKey.sidebarOpened(), state.sidebar.opened)
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device
    },
    SET_LANGUAGE: (state, language) => {
      state.language = language
      Cookies.set(LANGUAGE_KEY, language)
    },
  },
}
