<template>
  <el-config-provider :locale="locale">
    <!-- Display fullscreen loader until we know if user is already logged in or not -->
    <div
      v-if="isLoggedIn === null"
      v-loading.fullscreen.lock
    />
    <!-- User is already logged in -> `router-view` lives inside the layout component -->
    <layout
      v-else-if="isLoggedIn"
    />
    <!-- User needs to login in -->
    <login
      v-else
      @login-successful="reload"
    />

    <theme-picker
      v-show="false"
      :theme="companyColor"
      should-trigger-change
    />
  </el-config-provider>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { LANGUAGES } from '@/ui/consts'
import { RouteName } from '@/ui/router/consts.js'
import { userApi } from '@/ui/api/user.js'
import Layout from '@/ui/layout'
import Login from '@/ui/views/Login'
import ThemePicker from '@/ui/views/CompanyPreference/ThemePicker'

/* https://github.com/element-plus/element-plus/commit/3ab31b4d607d97355d5acf5887f37d3da0b8ca99
* TODO: This was a breaking change. Go back to using this once the new element-plus version includes an update to this.
import en from 'element-plus/lib/locale/lang/en'
import de from 'element-plus/lib/locale/lang/de'
*/
import en from '../../node_modules/element-plus/lib/locale/lang/en'
import de from '../../node_modules/element-plus/lib/locale/lang/de'

export default {
  name: 'App',
  components: {
    Layout,
    Login,
    ThemePicker,
  },
  data() {
    return {
      isLoggedIn: null,
    }
  },
  computed: {
    ...mapState({
      language: (state) => state.app.language,
    }),
    ...mapGetters({
      company: 'company/getCompany',
      customUserData: 'user/customData',
    }),
    locale() {
      return this.language === LANGUAGES.de ? de : en
    },
    companyColor() {
      return this.company?.styles?.primaryColor
    },
  },
  async mounted() {
    this.isLoggedIn = !!(await this.$store.dispatch('user/getAuthenticatedUserToken'))
  },
  methods: {
    ...mapActions({
      setUserCustomData: 'user/setCustomData',
    }),
    async reload() {
      if (!this.customUserData.preferred_language) {
        const browserLanguage = window.navigator.language.substring(0, 2) === 'de' ? 'de' : 'en'
        const { data } = await userApi.update({ ...this.customUserData, preferred_language: browserLanguage })
        await this.setUserCustomData(data)
      } else {
        await this.$store.dispatch('app/setLanguage', this.customUserData.preferred_language)
      }

      window.location.reload() // force full refresh
    },
  },
}
</script>
