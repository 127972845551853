const KEY_PREFIX = 'locoia-'

export const getLocalStorageKey = {
  /** Used for saving the local changes done to a flow */
  flowLocalChanges: (flowId) => `flowLocalChanges_${flowId}`,
  /** Used for saving the time details of the local changes that were done to a flow */
  flowLocalChangesTime: (flowId) => `flowLocalChangesTime_${flowId}`,
  /** Used to save the state of the connectors list sidebar for a flow */
  isConnectorsListOpen: (flowId) => `isConnectorsListOpen_${flowId}`,
  /** Used to determine whether we should show the warning for modifying/saving an 'active' flow */
  hideFlowActiveSave: () => 'hideFlowActiveSave',
  /** Used for copy -> pasting flows */
  flowDiagram: () => 'flowDiagram',
  /** Used to determine whether the sidebar is opened or closed */
  sidebarOpened: () => 'sidebarOpened',
}

/**
 * @typedef {Object} LocoiaLocalStorageItem
 * @property {any} value - the content of the item
 * @property {Number} _timestamp - date on which it was added
 */

export const locoiaLocalStorage = {
  clear: () => localStorage.clear(),
  /** TODO:Keyword: locoiaLocalStorage
   * Eventually most users will have their items stored with the prefix. Let's reconsider this in Feb 2025.
   * Keep only the `getItem` with `KEY_PREFIX`, if we will want to get a non-locoia item we should use the regular localStorage.
  */
  getItem: (keyName) => {
    const locoiaItem = localStorage.getItem(KEY_PREFIX + keyName)
    if (locoiaItem === null) return localStorage.getItem(keyName)

    try {
      return JSON.parse(locoiaItem).value
    } catch {
      return locoiaItem
    }
  },
  key: (index) => localStorage.key(index),
  /** TODO:Keyword: locoiaLocalStorage
   * Keep only the `removeItem` with `KEY_PREFIX`, if we will want to remove a non-locoia item we should use the regular localStorage.
  */
  removeItem: (keyName) => {
    localStorage.removeItem(KEY_PREFIX + keyName)
    localStorage.removeItem(keyName)
  },
  setItem: (keyName, keyValue) => {
    addNewTimestampedValue(keyName, keyValue)
  },
}

function addNewTimestampedValue(key, value) {
  /** @type {LocoiaLocalStorageItem} */
  const valueWithTimestamp = {
    value,
    _timestamp: Date.now(),
  }

  try {
    localStorage.setItem(KEY_PREFIX + key, JSON.stringify(valueWithTimestamp))
  } catch (err) {
    if (err.name === 'QuotaExceededError') {
      removeOldestAndRetry(key, value)
    }
  }
}

function removeOldestAndRetry(keyName, keyValue) {
  const items = getAllLocoiaItemsSorted()
  if (items.length === 0) {
    // We are trying to store an item that is too large for the localStorage and we have no locoia items to remove anymore to try to make space.
    // Since we don't want to remove localStorage items like Cognito, we will simply ignore this storage attempt.
    return
  }

  const oldestKey = items[0].key
  locoiaLocalStorage.removeItem(oldestKey)
  addNewTimestampedValue(keyName, keyValue)
}

function getAllLocoiaItemsSorted() {
  return Object.entries(localStorage)
    .filter(([key, value]) => key.startsWith(KEY_PREFIX))
    .map(([key, value]) => ({
      key,
      timestamp: getItemTimestamp(value),
    }))
    .sort((a, b) => a.timestamp - b.timestamp)
}

/**
 * Gets the saved timestamp in ms of the stored item.
 * @param {String} item
 * @returns {Number}
 */
function getItemTimestamp(item) {
  try {
    /** @type {LocoiaLocalStorageItem} */
    const parsedItem = JSON.parse(item)
    return parsedItem?._timestamp ?? 0
  } catch (error) {
    return 0
  }
}

export default locoiaLocalStorage
