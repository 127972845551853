export default {
  dashboard: 'Dashboard',
  grid: 'Grid',
  insight: 'Insights',
  transformation: 'Transformations',
  activityLog: 'Activity Log',
  introduction: 'Introduction',
  documentation: 'Dokumentation',
  help_docs: 'Help & Docs',
  user: 'Benutzer',
  company: 'Unternehmen',
  companyProfile: 'Company Profile',
  connectorActions: 'Connector Actions',
  connector: 'Connector',
  connectorAuth: 'Connector Auth',
  flows: 'Flows',
  flowBuilder: 'Flow Builder',
  flowEnvVariables: 'Flow Env Variables',
  profile: 'Profile',
  permission: 'Zugriffsrechte',
  pagePermission: 'Berechtigungen der Seite',
  directivePermission: 'Permisos de la directiva',
  icons: 'Icons',
  settings: 'Einstellungen',
  dataSources: 'Data Sources',
  myDashboards: 'Meine Dashboards',
  flowMonitoring: 'Flow Monitoring',
  flowdebuggerResult: 'Flow Debugger',
  debugging: 'Debuggen',
  flowbuilder: 'Flow Builder',
  flowdebugger: 'Flow Debugger',
  communityLibrary: 'Community Library',
  components: 'Komponenten',
  componentIndex: 'Introduction',
  tinymce: 'Tinymce',
  markdown: 'Markdown',
  jsonEditor: 'Editor JSON',
  dndList: 'Liste Dnd',
  splitPane: 'Split Panel',
  dropzone: 'Dateien hochladen',
  sticky: 'Sticky',
  countTo: 'CountTo',
  componentMixin: 'Mixin',
  dragDialog: 'Drag Dialog',
  dragSelect: 'Drag Select',
  dragKanban: 'Drag Kanban',
  example: 'Beispiel',
  nested: 'Nested',
  Table: 'Tabelle',
  dynamicTable: 'Dynamische Tabelle',
  dragTable: 'Dragbare Tabelle',
  inlineEditTable: 'Inline Editor',
  complexTable: 'Complex Table',
  treeTable: 'Tree Table',
  customTreeTable: 'Custom TreeTable',
  tab: 'Tab',
  form: 'Formular',
  createArticle: 'Artikel erstellen',
  editArticle: 'Artikel editieren',
  articleList: 'Artikelliste',
  errorPages: 'Fehlerseite',
  page401: '401',
  page404: '404',
  errorLog: 'Error Log',
  excel: 'Excel',
  exportExcel: 'Nach Excel exportieren',
  selectExcel: 'Excel auswählen',
  uploadExcel: 'Exel Upload',
  zip: 'Zip',
  pdf: 'PDF',
  exportZip: 'Exportar a Zip',
  theme: 'Theme',
  clipboardDemo: 'Clipboard',
  i18n: 'I18n',
  support: 'Support Center',
  fileUploader: 'Datei Uploader',
  team: 'Team',
  roles: 'Rollen',
  embed: 'Embed',
  configurations: 'Konfigurationen',
  integrations: 'Integrationen',
}
