import { DialogStatusEnum } from '@/ui/consts/global'

const create = {
  title: 'Env Variable erstellen',
  notification: {
    title: 'Erstellt',
    message: 'Env Variable wurde erstellt',
  },
}

const update = {
  title: 'Env Variable editieren',
  notification: {
    title: 'Gespeichert',
    message: 'Env Variable wurde gespeichert',
  },
}

export const envVariableDialog = {
  [DialogStatusEnum.create]: create,
  [DialogStatusEnum.update]: update,
  tags: 'Tags',
  variable: 'Env Variable',
  advancedView: 'Fortgeschrittene Ansicht',
  invalidVariable: 'Falsche Eingabe',
  basicView: {
    key: 'Schlüssel',
    value: 'Wert',
    type: 'Typ',
    addNewLine: 'Neue Werte Hinzufügen',
    textType: 'Text',
    otherType: 'Zahlen und andere',
  },
}
