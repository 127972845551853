import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { setPageTitle } from '@/ui/utils/route'

/**
 * Hash navigation guard.
 * We switched from hash-based navigation to history (/flowbuilder instead of /#/flowbuilder).
 * This navigation guard will allow us to still support hash links and redirect them to history ones.
 *
 * @param router
 */
export const hash = (router) => {
  router.beforeEach((to, from, next) => {
    let redirect

    if (to.hash) {
      const path = to.fullPath.split('#')
      path.shift()
      redirect = {
        path: path.join('#'),
        query: to.query,
        replace: true,
      }
    }

    next(redirect)
  })
}

/**
 * Page title navigation guard.
 * Set our page title after navigation. Page title can be defined per route via `meta`
 * and also later changed by calling setPageTitle() again.
 *
 * @param router
 */
export const pageTitle = (router) => {
  router.afterEach((to) => {
    setPageTitle(to)
  })
}

/**
 * Progress navigation guard.
 * Show the user a small loading indicator if he navigates to another page.
 *
 * @param router
 */
export const progress = (router) => {
  NProgress.configure({ showSpinner: false })

  router.beforeEach((to, from, next) => {
    NProgress.start()
    next()
  })

  router.afterEach(() => {
    NProgress.done()
  })
}
