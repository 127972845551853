import { DialogStatusEnum } from '@/ui/consts/global'

const create = {
  title: 'Create Env Variable',
  notification: {
    title: 'Updated',
    message: 'Env Variable has been created',
  },
}

const update = {
  title: 'Edit Env Variable',
  notification: {
    title: 'Updated',
    message: 'Env Variable has been updated',
  },
}

export const envVariableDialog = {
  [DialogStatusEnum.create]: create,
  [DialogStatusEnum.update]: update,
  tags: 'Tags',
  variable: 'Env variable',
  advancedView: 'Advanced view',
  invalidVariable: 'Invalid input',
  basicView: {
    key: 'Key',
    value: 'Value',
    type: 'Type',
    addNewLine: 'Add line',
    textType: 'Text',
    otherType: 'Numbers and others',
  },
}
