export default {
  name: 'Flow Name',
  recent: 'Flow Runs',
  run_time: 'Letzte Run-Zeit',
  start_time: 'Start-Zeit',
  duration: 'Dauer',
  monitoring: {
    quick_stats: 'Alle Flow Runs (letzte 7 Tage)',
    quick_action: 'Schnelle Aktionen',
    your_flows: 'Zuletzt aktualisierte Flows',
    running_flows: 'Aktuell laufende Flows',
    long_running_flows: 'Lang laufende Flows',
    flow_run_history: 'Zuletzt gelaufene Flows',
  },
  create_connector_auth: 'Connector Authentication erstellen',
  create_flow: 'Flow erstellen',
  create_user: 'Benutzer erstellen',
  search: 'Flow suchen... --> Salesforce, Zendesk or Magento tippen',
  navigateFlowbuilder: 'Zum Flow Builder',
  navigateFlowbuilderTooltip: 'Zurück zum Flow Builder',
  learnMore: 'Mehr erfahren ...',
  noResultShown: {
    reasons: 'Aus folgenden möglichen Gründen wird kein Ergebnis angezeigt:',
    notRunReason: 'Der Schritt wurde noch nicht ausgeführt',
    retentionRateReason: 'Die Daten des Schritts wurden gemäß den Aufbewahrungseinstellungen gelöscht',
    nilRetentionRateReason: 'Die Datenaufbewahrungsdauer für den Flow ist auf 0 Tage eingestellt. Wir empfehlen, sie während der Erstellung und des Tests eines Flows auf mindestens 1 Tag einzustellen.',
    controllerBusyReason: 'Es liegt eine hohe Anfragelast vor und die Ausführung wurde noch nicht registriert. Bitte versuchen Sie, in einem Moment zu aktualisieren.',
  },
  looper: {
    of: ' von',
  },
  props: {
    looper_completed_iterations: 'Iterationen:',
    looper_total_iterations: 'Iterationen insgesamt:',
    looper_iteration_index: 'Iteration index:',
    automatic_pagination_pages: 'Abgerufene Seiten:',
    created_date: 'Start:',
    updated_date: 'Ende:',
    start_date: 'Start:',
    end_date: 'Ende:',
    retrying_in: 'Wird in {amount} wiederholt',
    request_url: 'Request URL:',
    response_status: 'Response Code:',
    flow_trigger: 'Flow Auslöser',
    triggered_by_flow: 'Ausgelöst durch Flow',
    triggered_by_user: 'Ausgelöst durch Nutzer',
    cancel_types: {
      user: 'Abgebrochen durch Nutzer',
      step_size: 'Abgebrochen durch das Step Limit',
    },
    original_flow_run: 'Originaler Flow Run um',
    trigger_types: {
      scheduler: 'Scheduler',
      webhook_async: 'Webhook (Async)',
      webhook_sync: 'Webhook (Sync)',
      multi_flow_trigger_async: 'Multi Flow Trigger (Async)',
      multi_flow_trigger_sync: 'Multi Flow Trigger (Sync)',
      re_run: 'Wiederholung',
      super_action: 'Super Action',
      manual: 'Manuell',
      pre_save_flow: 'Speichern Flow',
      on_delete_flow: 'Löschen Flow',
      file_uploader: 'Datei Uploader',
      error_handling: 'Fehlerhandhabung',
      single_step: 'Einzelschrittausführung',
    },
  },
  schemaExtenderTitle: 'Benutzerdefinierter API-Felder hinzufügen',
  custumFieldTitle: 'Benutzerdefinierter Feldname (der Feld-Name/Key der API)',
  custumFieldType: 'Field Typ',
  toolbar: {
    newFlowActionText: 'Neuer Flow',
    newFlowActionTooltip: 'Einen neuen Flow erstellen',

    saveFlowActionText: 'Speichern',
    saveFlowActionTooltip: 'Speicher den Flow in deine Sammlung',

    loadFlowActionText: 'Laden',
    loadFlowActionTooltip: 'Lade ein existierenden Flow aus deiner Sammlung',

    settingsActionTooltip: 'Bearbeiten Sie die Einstellungen dieses Ablaufs',

    copyFlowActionText: 'Kopieren',
    copyFlowActionTooltip: 'Kopieren',

    runFlowActionText: 'Starten',
    runFlowActionTooltip: 'Starte einen Flow-Ablauf jetzt um diesen zu testen',

    debugFlowActionText: 'Debug Flow',
    debugFlowActionTooltip: 'Schau Dir die Debug-Ausgaben des Flows an',

    deleteFlowActionText: 'Löschen',
    deleteFlowActionTooltip: 'Lösche den Flow und alle Debug-Ausgaben',

    showFlowInfoText: 'Tipps',
    showFlowInfoTooltip: 'Shortcuts und Tipps zum Flow-Aufbau 🚀',

    shareFlowActionText: 'Zugriffsrechte',
    shareFlowActionTooltip: 'Zugriffsrechte',

    versioningFlowText: 'Version History',
    versioningFlowTooltip: 'Version-Historie',

    toggleReferencePanelText: 'Referenz-Panel',
    toggleReferencePanelTooltip: 'Funktionsreferenz-Panel für Schritte umschalten',

    insufficientPermissions: 'Unzureichende Berechtigungen',
    insufficientPermissionsToDeleteTooltip: 'Unzureichende Berechtigungen zum Löschen des Ablaufs',
    insufficientPermissionsToVersioningTooltip: 'Unzureichende Berechtigungen, um die Versionsgeschichte anzuzeigen',
    insufficientPermissionsToSaveTooltip: 'Unzureichende Berechtigungen, um den Ablauf zu speichern',
    insufficientPermissionsToShareTooltip: 'Unzureichende Berechtigungen, um die Freigabeeinstellungen zu bearbeiten',
    insufficientPermissionsToRunTooltip: 'Unzureichende Berechtigungen, um diesen Ablauf auszuführen',
    insufficientPermissionsToCancelFlowRun: 'Unzureichende Berechtigungen zum Abbrechen des flow',
    insufficientPermissionsToRetryFlowRun: 'Unzureichende Berechtigungen, um den Ablauf erneut zu versuchen',
    insufficientPermissionsToRunFlowRunSuperAction: 'Ein Superaktion-Flusslauf kann nicht gestartet werden. Bitte führen Sie stattdessen den Fluss aus, der die Superaktion verwendet',
    insufficientPermissionsToRetryFlowRunSuperAction: 'Ein Superaktion-Flusslauf kann nicht erneut versucht werden. Bitte versuchen Sie stattdessen den Fluss, der die Superaktion verwendet',
  },
  unsaved: {
    connectorAdded: 'Connector wurde hinzugefügt',
    connectorMoved: 'Connector wurde bewegt',
    connectorChanged: 'Connector wurde verändert',
    connectorDeleted: 'Connector wurde gelöscht',
    linkUpdated: 'Link wurde bewegt',
    linkDeleted: 'Link wurde gelöscht',
    formUpdated: 'Änderungen im Formular',
    attention: 'Achtung',
    lostChanges: 'Folgende Änderungen würden verworfen werden:',
    saveBeforeRun: 'Du kannst keinen Flow starten bevor nicht folgende Änderungen gespeichert wurden:',
    linkAdded: 'Link wurde hinzugefügt',
    errorLinkAdded: 'Error link wurde hinzugefügt',
    linkConnectsTwoNodes: 'Link verbindet {sourceNodeSelector} und {targetNodeSelector}',
    linkConnectedToANode: 'Link verbunden mit {node}',
  },
  zoom_hint: 'Um Zoom und Fokus des Workspace anzupassen klicken oder CMD oder STRG drücken + Mouse-Scroll und mit dem Mauszeiger de Fokus bestimmen',
  advancedSchemaView: {
    expectsType: 'Erwarte {type}',
    expectsValues: 'Erwarte einen der folgenden Werte: {values}',
    fieldKey: 'Schlüssel: {key}',
    title: 'Erweitere Ansicht',
  },
  savingErrors: {
    selfLinkError: '{name} - {selector}: Abgehende Pfeile dürfen nicht mit dem eigenen Connector verbunden werden!',
    excessiveLinkWarning: '{name} - {selector}: Du hast mehr als einen abgehenden Pfeil verwendet!',
    excessiveErrorLinkWarning: '{name} - {selector}: Du hast mehr als einen "Error"-Pfeil verwendet!',
    excessiveActionLoopLinkWarning: '{name} - {selector}: Du hast mehr als einen "ActionLoop"-Pfeil verwendet!',
    booleanLinksWarning: '{name} - {selector}: Der Boolean Helper kann nur einen "True" und/oder einen "False" Pfeil haben!',
    actionLoopArrowIsRequired: '{name} - {selector}: "ActionLoop"-Pfeil ist für den Looper Helper erforderlich. Du kannst ihn hinzufügen, indem du einen "Link" erstellst und darauf doppelklickst, um "ActionLoop" einzugeben.',
    actionLoopHasNoTarget: '{name} - {selector}: "ActionLoop"-Pfeil muss auf einen Schritt zeigen!',
    emptyAction: '{name} - {selector}: Hat keine {action}. Bitte legen Sie diese durch Doppelklick auf den Connector fest und übernehmen Sie die Änderungen.',
    multiCaseHelperLabels: '{name} - {selector}: Kann keine Pfeile ohne Beschriftungen haben!',
    referenceIsASystemWideConstant: 'Die Referenz für "{title}" kann nicht "{selector}" sein, da es sich um eine systemweite Konstante handelt',
    referenceFormatIsNotValid: 'Das Format der Schritt-Referenz ist ungültig',
    invalidConnectorAuth: 'Connector Auth im Connector-Schritt {selector} enthält keine der diesem Flow zugewiesenen Tags: {flowTags}.',
  },
  activeSaveFlow: {
    buttonChangeToPaused: 'Status in Paused ändern und speichern',
    message: 'Es empfiehlt sich, den Flow erst zu pausieren, um unerwünschte Fehler zu vermeiden!',
  },
  restoreFromLocal: {
    description: 'Möchten Sie Ihre Änderungen von der letzten automatischen Speicherung von {localDate} ({localDistance})' +
      ' wiederherstellen oder die letzte manuell gespeicherte Version von {remoteDate} ({remoteDistance}) behalten?',
    discard: 'Letzte manuell gespeicherte Version beibehalten',
    restore: 'Automatisch gespeicherte Version wiederherstellen',
    restoreToNewFlow: 'Automatisch gespeicherte Version als neuen Flow öffnen',
  },
  editModeTitle: {
    stepTitle: 'Schritt-Titel',
    refId: 'Schritt-Referenz',
    dynamicTitlePostfix: 'Dynamic Titel Postfix',
    dynamicAuthId: 'Dynamic Authentication ID',
    errorHandler: 'Fehlerhandhabung',
    errorHandlingFlowEnabled: 'Löst Fehlerhandhabungs-Flow aus',
    accessibleInErrorHandlingFlow: 'Erreichbar in Fehlerhandhabungs-Flow',
    description: 'Beschreibung',
  },
  errorHandlingOptions: {
    terminate: 'Abbrechen',
    continue: 'Fortsetzen',
    skip_loop: 'Loop Iteration überspringen',
    break_inner_loop: 'Aktuellen Loop unterbrechen',
    manual: 'Manuelle Fehlerhandhabung',
  },
  errorHandlingFlow: 'Fehlerhandhabungs-Flow',
  errorHandlingFlowTooltip: 'Ein Flow, um alle Fehler zu handhaben oder Fehler zu protokollieren und Fehlerprotokolle an einen Connector der Wahl zu senden. Mehr Informationen befinden sich in unserer Dokumentation.',
  errorHandlingStepConfirmDeleteMessage: 'Die Ausgabe des Schritts wird im Fehlerbehandlungsfluss verwendet. Sind Sie sicher, dass Sie diesen Schritt löschen möchten?',
  errorHandlingStepConfirmDeleteTitle: 'Schritt löschen: {stepTitle}?',
  retrieveAllData: 'Alle Daten abrufen',
  oneNodePerFlowError: 'Nur ein <strong style="color: #4372C4">{nodeName}</strong> ist pro Flow erlaubt.',
  actionLoopRequires: 'Der <strong style="color: #4372C4">ActionLoop</strong> Pfeil wird für den <strong style="color: #4372C4">Looper Helper</strong> <strong>benötigt</strong>.',
  enableFunctionReferencePanel: 'Funtions Reference Panel wurde aktiviert',
  disableFunctionReferencePanel: 'Funtions Reference Panel wurde deaktiviert',
  updateBulkFlowsError: 'Sie können nur 50 Flows auf einmal in Massen aktualisieren',
  enableFilteringByTags: 'Filterung nach Tags aktivieren',
  enableFilteringByTagsExplanation: 'Im Flow Builder sind nur Connector Auths zugänglich, die mindestens ein gemeinsames Tag mit dem Flow haben.',
  functionsReference: 'Funktionsreferenz',
  metadata: 'Metadaten',
}
