export default {
  Password: 'Passwort',
  'Enter your Password': 'Passwort eingeben',
  'Forgot your password?': 'Passwort vergessen?',
  'Reset Password': 'Passwort zurücksetzen',
  'Sign In': 'Anmelden',
  'No account? ': 'Noch kein Account?',
  'Create account': 'Registrieren',
  'Sign in to your account': 'Anmelden',
  Email: 'E-Mail',
  'Create Account': 'Registrieren',
  'Have an account? ': 'Account vorhanden?',
  'Sign in': 'Anmelden',
  'Create a new account': 'Registrieren',
  'Your password': 'Passwort',
  'Your phone number without country code': 'Ihre Telefonnummer ohne Ländervorwahl',
  'Phone number': 'Telefonnummer',
  Code: 'Code',
  Confirm: 'Bestätigen',
  'Back to Sign In': 'Zurück zur Anmeldung',
  'Confirm Sign In (Code via SMS)': 'Anmeldung bestätigen (Code via SMS)',
  'Confirmation Code': 'Bestätigungs-Code',
  'Lost your code? ': 'Code verloren?',
  'Resend Code': 'Code erneut senden',
  'Confirm Sign Up': 'Registrierung bestätigen',
  'New Password': 'Neues Passwort',
  'Send code': 'Code senden',
  Submit: 'Abschicken',
  'Reset your password': 'Passwort zurücksetzen',
  'Enter your phone number': 'Telefonnummer eingeben',
  'Enter new password': 'Neues Passwort eingeben',
  'Verify Authentication Token': 'Zugangsdaten verifizieren',
  'Verification Code': 'Bestätigungs-Code',
  'Set MFA': 'Mehrfaktor-Authentifizierung initialisieren',
  'Verify Token': 'Daten verifizieren',
  Cancel: 'Abbrechen',
  'Sign Out': 'Logout',
  'Enter your email': 'E-Mail eingeben',
  'Your Email': 'Ihre E-Mail',
  'Confirm sign in via SMS code': 'Bitte bestätigen Sie ihre Anmeldung via SMS-Code',
  'Incorrect username or password.': 'Nutzername oder Passwort falsch.',
  'User does not exist.': 'Nutzername existiert nicht.',
  'Username cannot be empty': 'Nutzername darf nicht leer sein.',
  'Username/client id combination not found.': 'Nutzername nicht gefunden.',
  'SignUp is not permitted for this user pool': 'Registrierung ist momentan nicht möglich.',
  Username: 'Nutzername',
  'Enter your username': 'Nutzername eingeben',
  'Custom auth lambda trigger is not configured for the user pool.': 'Bitte Passwort eingeben.',
}
